<template>
	<el-dialog :visible.sync="show" width="45%" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
               :title="info.title" center
	 @close="close">
<!--		<div v-if="!userShowList">-->
<!--			<div slot="title" class="header-title">-->
<!--				<el-row :gutter="5">-->
<!--					<el-col :span="24">-->
<!--						<span class="title-name" style="font-size: 20px;">{{ info.title }}</span>-->
<!--					</el-col>-->
<!--				</el-row>-->
<!--			</div>-->
<!--			<div style="width: 100%;height: 20px;"></div>-->
			<!-- <el-row>
				<el-col :span="12">
					操作模块：
				</el-col>
				<el-col :span="12">
					请求地址：
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					登录信息：
				</el-col>
				<el-col :span="12">
					请求地址：
				</el-col>
			</el-row> -->
			<el-row style="line-height: 20px;">
				<el-col :span="3" style="font-size: 16px">
					请求参数：
				</el-col>
				<el-col :span="21">
					{{operParam}}
				</el-col>
			</el-row>
			<el-row style="padding-top: 20px;line-height: 20px;">
				<el-col :span="3"  style="font-size: 16px">
					返回参数：
				</el-col>
				<el-col :span="21">
					{{jsonResult}}
				</el-col>
			</el-row>
			<!-- <div slot="footer" class="dialog-footer" style="text-align: center;">
				<el-button type="primary" @click="commit">提交</el-button>
				<el-button type="info" @click="close">取消</el-button>
			</div> -->
<!--		</div>-->
	</el-dialog>
</template>

<script>
	export default {
		name: 'Operate',
		props: {
			// 打开dialog的
			dialog: {
				type: Boolean, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: false // 这样可以指定默认的值
			},
			info: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			},
			row: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			}
		},
		data() {
			return {
				show: this.dialog,
				loading: false,
				operParam: '',
				jsonResult: '',
			}
		},
		created() {
			this.getList();
		},
		mounted() {},
		methods: {
			// 关闭模态框
			close() {
				this.show = false
				this.$emit('refresh')
			},
			getList() {
				this.loading = true
				this.$axiosReq('/oauth/server/escort/web/opLog/' + this.row.id, null, null, 'get').then(res => {
					this.jsonResult = res.data.jsonResult;
					this.operParam = res.data.operParam;
				}).finally(e => {
					this.loading = false
				})
			},
		}
	}
</script>

<style scoped></style>
