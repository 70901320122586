<template>
	<div class="dashboard-container">
		<Operate v-if="dialog.refresh" :dialog.sync="dialog.show" :row="dialog.row" :info="dialog.info" @get-list="getList"
		 @refresh="refreshDialog" />
		<el-card>
			<el-row type="flex">
				<el-col :span="7">
					<span class="text-primary text-pad-right">日期：</span>
					<el-date-picker v-model="date" type="daterange" class="input searchInput" range-separator="至"
					 start-placeholder="开始日期" end-placeholder="结束日期" clearable value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00','23:59:59']"
					 size="mini" @change="changeTime" />
				</el-col>
				<el-col :span="7">
					<div class="pl-1">
						<span class="text-primary text-pad-right">操作人员：</span>
						<el-input v-model="userName" size="mini" class="input searchInput" suffix-icon="el-icon-search"
						 placeholder="请输入操作人员" @input="getList" style="width: 260px;" />
					</div>
				</el-col>
			</el-row>
		</el-card>
		<el-card style="margin-top: 20px;">
			<el-row>
				<!-- <vxe-toolbar>
					<template v-slot:buttons>
						<el-row type="flex" justify="between">
							<el-col>
								<el-button type="primary" size="mini" @click="openDialog({title:'添加公告',addOr:'add'},'')">添加公告</el-button>
								<el-button type="danger" size="mini" @click="remove({del:'batch'})">批量删除</el-button>
								<el-button type="primary" size="mini" @click="exportTableE1">导出excel</el-button>
							</el-col>
							<el-col :span="1">
								<vxe-button @click="getList">刷新</vxe-button>
							</el-col>
						</el-row>
					</template>
				</vxe-toolbar> -->
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
				 align="center" class="vxe-table-element" height="670" row-id="notice_id" :sort-config="{remote: true}"
				 :filter-config="{remote: true}" :checkbox-config="{reserve: true}" :data="table.list" :loading="loading">
					<!-- <vxe-table-column type="checkbox" width="60" fixed="left" /> -->
					<vxe-table-column type="seq" width="60" />
					<vxe-table-column field="operName" title="操作人员" show-overflow="title" />
					<vxe-table-column field="operTime" title="操作时间" show-overflow="title" />
					<vxe-table-column field="operatorTypeStr" title="操作类别" show-overflow="title" />
					<vxe-table-column field="requestMethod" title="请求方式" show-overflow="title" />
					<vxe-table-column field="statusStr" title="操作状态" show-overflow="title" />
					<vxe-table-column field="title" title="操作模块" show-overflow="title" />
					<vxe-table-column field="businessTypeStr" title="业务类型" show-overflow="title" />
					<vxe-table-column field="errorMsg" title="错误消息" show-overflow="title" />
					<vxe-table-column field="operUrl" title="请求url" show-overflow="title" />
					<vxe-table-column field="operIp" title="操作地址" show-overflow="title" />
					<vxe-table-column title="详细">
						<template v-slot="{ row }" show-overflow="title">
							<div @click="openDialog( { title: '操作日志详细', addOr: 'upd', }, row )" style="color: #00A0E9;cursor: pointer;">点击查看</div>
						</template>
					</vxe-table-column>
				</vxe-table>
				<div style="height: 20px" />
				<el-pagination background :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]"
				 :page-size="table.pageSize" layout="total, ->, sizes, prev, pager, next, jumper" :total="table.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" />
			</el-row>
		</el-card>
	</div>
</template>

<script>
	import {
		rowStyle,
		headerStyle
	} from '@/utils/tableStyleJs'
	import Operate from './Operate'
	export default {
		name: 'Index',
		components: {
			Operate
		},
		data() {
			return {
				loading: false,
				startDate: '',
				endDate: '',
				date: '',
				releaseStatus: '',
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 20,
					sortName: '',
					sortBy: ''
				},
				dialog: {
					row: Object,
					info: {},
					refresh: false, // 每次刷新DOM
					show: false
				},
				userName: ''
			}
		},
		created() {
			this.getList();
		},
		methods: {
			rowStyle,
			headerStyle,
			getList() {
				this.loading = true
				this.$axiosReq('/oauth/server/escort/web/opLog', null, {
					startTime: this.startDate ? this.startDate : null,
					endTime: this.endDate ? this.endDate : null,
					currentPage: this.table.currentPage,
					pageSize: this.table.pageSize,
					userName: this.userName,
				}, 'get').then(res => {
					this.table.list = res.data.content
					this.table.total = Number(res.data.totalElements)
				}).finally(e => {
					this.loading = false
				})
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize
				this.table.currentPage = 1
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage
				this.getList()
			},
			changeTime(v) {
				if (v === null) {
					this.startDate = ''
					this.endDate = ''
				} else {
					this.startDate = v[0]
					this.endDate = v[1]
				}
				this.getList()
			},
			openDialog(info, row) {
			  this.dialog.refresh = true
			  this.dialog.show = true
			  this.dialog.info = info
			  this.dialog.row = row
			},
			refreshDialog() {
				this.dialog.refresh = false
			}
		}
	}
</script>

<style scoped>
.rowBox {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
</style>
